


import {Component, Vue, Watch} from 'vue-property-decorator';
import { TNewsIssue } from '@/_types/news-issue.type';
import iconCloseCircleOutline from '@/_modules/icons/components/icon-close-circle-outline.vue';
import newsApi from '@/_api/news.api';
import DateTimeHelper from '@/_helpers/date-time.helper';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';

@Component({
  components: {
    iconCloseCircleOutline
  }
})
export default class NewsIssue extends Vue {

  public newsIssue: TNewsIssue | void = null;
  public isNewsIssueLoading: boolean = false;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get newsIssueId(): number {
    return this.$route.params.newsIssueId ? parseInt(this.$route.params.newsIssueId, 10) : null;
  }

  public get mainImageBackground(): string {
    if (!this.newsIssue || !this.newsIssue.photos || !this.newsIssue.photos.length) {
      return 'none';
    }

    return 'url(' + this.newsIssue.photos[0] + ')';
  }

  public get formattedPublicationDate(): string {
    if (!this.newsIssue || !this.newsIssue.created_at) {
      return '';
    }
    return DateTimeHelper.newsPublicationDate(this.$moment(this.newsIssue.created_at), this.$i18n.locale);
  }

  public mounted(): void {
    this.getNewsIssue();
  }

  @Watch('newsIssueId')
  private onNewsIssueIdChange(): void {
    this.getNewsIssue();
  }

  private async getNewsIssue(): Promise<void> {
    this.isNewsIssueLoading = true;
    this.newsIssue = null;
    this.newsIssue = await newsApi.getNewsIssue({eventId: this.eventId, newsId: this.newsIssueId})
      .catch(() => {
        this.$router.push({
          name: 'news'
        }).catch(() => {
          /* ignore */
        });
      });
    this.isNewsIssueLoading = false;
  }

  private onCloseNewsIssueClick(): void {
    this.$router.push({
      name: 'news'
    }).catch(() => {
      /* ignore */
    });
  }

  private linkifyUrls(inputString: string): string {
    return ChatHelper.createLinks(inputString);
  }
}
